import { Hits } from '@mezereon/ui-components-vue'
import ExtendItem from './extendItem'

export default {
  mixins: [Hits, ExtendItem],
  props: {
    inner: {
      type: Object,
      required: false,
      default: null
    },
    item: {
      type: Object,
      required: false
    },
    score: {
      type: Number,
      required: false
    },
    index: {
      type: Number,
      required: false
    },
    pageTotal: {
      type: Number,
      required: false,
      default: 0
    }
  }
}
