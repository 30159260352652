import Vue from 'vue'
import axios from 'axios'

const Axios = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})

const isAbsoluteURLRegex = /^(?:\w+:)\/\//
const getApiUrl = Vue => Vue.prototype.$store.state.api.apiUrl
const getSearchKey = Vue => Vue.prototype.$store.state.api.searchKey

Axios.interceptors.request.use(function(config) {
  // ensure absolute url
  if (!isAbsoluteURLRegex.test(config.url)) {
    config.url = `${getApiUrl(Vue)}${config.url}`
  }
  config.headers.Authorization = `Bearer ${getSearchKey(Vue)}`

  return config
})

export default {
  Axios,
  install(Vue, options) {
    Vue.prototype.$axios = Axios
  }
}
