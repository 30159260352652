<template>
  <base-search-box
    ref="child"
    v-bind="$attrs"
    @autocomplete-open="autocompleteOpen"
    @autocomplete-close="autocompleteClose"
    @change-autocomplete-view="changeAutocompleteView"
  >
    <template
      #list-item="{
        hit,
        index,
        primaryKey,
        config,
        isHidden,
        onItemClick
      }"
    >
      <autocomplete-item
        :inner="hit.inner"
        :item="hit.item"
        :score="hit.score"
        :index="index"
        :primary-key="primaryKey"
        :template="config.listViewTemplate"
        :hidden="isHidden(hit)"
        @item-click="onItemClick"
      />
    </template>
    <template
      #grid-item="{
        hit,
        index,
        primaryKey,
        config,
        isHidden,
        onItemClick
      }"
    >
      <autocomplete-item
        :inner="hit.inner"
        :item="hit.item"
        :score="hit.score"
        :index="index"
        :primary-key="primaryKey"
        :template="config.gridViewTemplate"
        :hidden="isHidden(hit)"
        @item-click="onItemClick"
      />
    </template>
  </base-search-box>
</template>

<script>
import { BaseSearchBox } from '@mezereon/ui-components-vue'
import AutocompleteItem from './AutocompleteItem'
import { convertCurrency } from '@/helpers'

export default {
  name: 'MzShopifySearchBox',
  components: {
    BaseSearchBox,
    AutocompleteItem
  },
  inheritAttrs: false,
  methods: {
    closeOverlay() {
      this.$refs.child.closeOverlay()
    },
    autocompleteOpen() {
      setTimeout(() => convertCurrency('.mz-autocomplete-container .money'), 20)
      if (this.$bus) this.$bus.emit('autocomplete-open')
    },
    autocompleteClose() {
      if (this.$bus) this.$bus.emit('autocomplete-close')
    },
    changeAutocompleteView() {
      setTimeout(() => convertCurrency('.mz-autocomplete-container .money'), 20)
      if (this.$bus) this.$bus.emit('change-autocomplete-vue')
    }
  }
}
</script>
