var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-search-box',_vm._b({ref:"child",on:{"autocomplete-open":_vm.autocompleteOpen,"autocomplete-close":_vm.autocompleteClose,"change-autocomplete-view":_vm.changeAutocompleteView},scopedSlots:_vm._u([{key:"list-item",fn:function(ref){
    var hit = ref.hit;
    var index = ref.index;
    var primaryKey = ref.primaryKey;
    var config = ref.config;
    var isHidden = ref.isHidden;
    var onItemClick = ref.onItemClick;
return [_c('autocomplete-item',{attrs:{"inner":hit.inner,"item":hit.item,"score":hit.score,"index":index,"primary-key":primaryKey,"template":config.listViewTemplate,"hidden":isHidden(hit)},on:{"item-click":onItemClick}})]}},{key:"grid-item",fn:function(ref){
    var hit = ref.hit;
    var index = ref.index;
    var primaryKey = ref.primaryKey;
    var config = ref.config;
    var isHidden = ref.isHidden;
    var onItemClick = ref.onItemClick;
return [_c('autocomplete-item',{attrs:{"inner":hit.inner,"item":hit.item,"score":hit.score,"index":index,"primary-key":primaryKey,"template":config.gridViewTemplate,"hidden":isHidden(hit)},on:{"item-click":onItemClick}})]}}])},'base-search-box',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }