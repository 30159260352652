const english = {
  Filter: 'Filter',
  Data: 'Data',
  List: 'List',
  Grid: 'Grid',
  PageSummary: 'Page {current_page} of {last_page},',
  SummaryCount: 'Item Count: {total}, took: {took} ms',
  'Sort by': 'Sort by',
  'Quick Lookup': 'Quick Lookup',
  '(+) Show More': '(+) Show More',
  '(-) Show Less': '(-) Show Less',
  'Clear All': 'Clear All'
}

function translate(code, values) {
  const dictionary = this.$store.state.search.translation || english
  let value = dictionary[code] !== undefined ? dictionary[code] : code
  if (values instanceof Object && !Array.isArray(values)) {
    Object.keys(values).forEach(k => {
      value = value.replace(new RegExp('{{\\s?' + k + '\\s?}}', 'g'), values[k])
      value = value.replace(new RegExp('{' + k + '}', 'g'), values[k])
    })
  }
  return value
}

export default {
  install(Vue, options) {
    Vue.prototype.$t = translate
  }
}
